.freezed-row {
  background-color: rgb(199, 190, 190);
}

.non-working-row {
  background-color: rgb(241, 241, 132);
}

.no-default-placement-row {
  background-color: rgb(245, 184, 143);
}